/* eslint-disable standard/no-callback-literal */

// const testPane = function (rule, value, cb) {
//   if (value) {
//     for (var i = 0; i < value.length; i++) {
//       if (other_tax_contry === '' || other_tax_num === '') {
//         cb(false)
//       }else{
//         cb()
//       }
//     }
//   } else {
//     cb(false)
//   }
// }

const fileValidator = { required: true, message: 'Please submit correct file', trigger: 'blur' }
const emailValidator = {
  required: true,
  type: 'email',
  message: 'Please input correct email',
  trigger: 'blur'
}
const baseRequirement = { required: true, message: 'Please fill in the correct value', trigger: 'blur' }
const baseRequirementChange = {
  required: true, message: 'Please fill in the correct value', trigger: 'change'
}

export default {
  addressProveType: [
    { pattern: /^[1-6]$/, required: true, message: 'Please fill in the correct value', trigger: 'blur' }
  ],
  post_code: [
    baseRequirement

  ],
  live_country: [
    baseRequirement
  ],
  po_box_address: [
    baseRequirement
  ],
  identification_type: [
    baseRequirement
  ],
  id_num: [
    baseRequirement
  ],
  employer_company_name: [
    baseRequirement
  ],
  issue_date: [
    baseRequirement
  ],
  expiry_date: [
    baseRequirement
  ],
  issue_country: [
    baseRequirement
  ],
  has_other_country_tax: [
    { required: true, message: 'Please fill in the correct value', trigger: 'change' }
  ],
  nz_ird_num: [
    { required: true, message: 'Please fill in the correct value', trigger: 'blur' }
  ],
  none_face_to_face_type: [{ pattern: /^[1-4]$/, required: true, message: 'Please fill in the correct value', trigger: 'blur' }],
  face_to_face_type: [{ pattern: /^[1-3]$/, required: true, message: 'Please fill in the correct value', trigger: 'blur' }],
  first_name: [
    baseRequirement
  ],
  // id
  nz_au_passport_country: [
    baseRequirement
  ],
  nz_au_passport_num: [
    baseRequirement
  ],
  nz_au_passport_file_list: [
    fileValidator
  ],
  nz_au_driver_num: [
    baseRequirement
  ],
  nz_au_driver_country: [
    baseRequirement
  ],
  nz_au_driver_front_file_list: [
    fileValidator
  ],

  nz_au_driver_back_file_list: [
    fileValidator
  ],
  cn_passport_num: [
    baseRequirement
  ],

  cn_passport_country: [
    baseRequirement
  ],
  cn_passport_file_list: [
    fileValidator
  ],
  cn_passport_visa_num: [
    baseRequirement
  ],
  cn_passport_visa_country: [
    baseRequirement
  ],
  cn_passport_visa_file_list: [
    fileValidator
  ],
  cn_identify_front_num: [
    baseRequirement
  ],
  cn_identify_front_country: [
    baseRequirement
  ],
  cn_bank_front_num: [
    baseRequirement
  ],
  cn_bank_front_country: [
    baseRequirement
  ],
  cn_bank_front_file_list: [
    fileValidator
  ],
  cn_bank_back_file_list: [
    fileValidator
  ],
  cn_identify_back_file_list: [
    fileValidator
  ],
  cn_identify_front_file_list: [
    fileValidator
  ],
  cn_passport_not_required_num: [
    baseRequirement
  ],
  cn_passport_not_required_country: [
    baseRequirement
  ],
  cn_passport_not_required_file_list: [
    fileValidator
  ],
  cn_passport_not_required_visa_num: [
    baseRequirement
  ],
  cn_passport_not_required_visa_country: [
    baseRequirement
  ],
  cn_passport_not_required_visa_file_list: [
    fileValidator
  ],
  other_identify_num: [
    baseRequirement
  ],
  other_identify_country: [
    baseRequirement
  ],
  other_identify_file_list: [
    fileValidator
  ],
  other_passport_visa_num: [
    baseRequirement
  ],
  other_passport_visa_country: [
    baseRequirement
  ],
  other_passport_visa_file_list: [
    fileValidator
  ],
  last_name: [
    baseRequirement
  ],
  email_address: [
    emailValidator],
  nationID_driver_license_num: [
    { required: true, message: 'Please fill in the correct value', trigger: 'blur' }
  ],
  prove_channel: [
    { required: true, message: 'Please select the correct option', trigger: 'blur' }
  ],
  residential_address: [
    baseRequirement
  ],
  country: [
    baseRequirement
  ],
  city: [
    baseRequirement
  ],
  postcode: [
    baseRequirement
  ],
  personal_dial_code: [
    baseRequirement
  ],
  address_prove_type: [
    baseRequirement
  ],
  title: [
    baseRequirement
  ],
  mobile_phone: [
    baseRequirement
  ],
  gender: [
    baseRequirement
  ],
  date_of_birth: [
    baseRequirement
  ],
  place_of_birth: [
    baseRequirement
  ],
  nationality: [
    baseRequirement
  ],
  is_nz_tax_resident: [
    baseRequirementChange
  ],
  live_city: [
    { required: true, message: 'Please fill in the correct value', trigger: 'blur' }
  ],
  us_taxer_file_list: [
    fileValidator
  ],
  bank_stament_address_file_list: [
    fileValidator
  ],
  utility_address_file_list: [
    fileValidator
  ],
  government_correspondence_address_file_list: [
    fileValidator
  ],
  commission_correspondence_address_file_list: [
    fileValidator
  ],
  tenancy_agreement_address_file_list: [
    fileValidator
  ],
  vehicle_register_address_file_list: [
    fileValidator
  ]
}
